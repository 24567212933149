// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")



import $ from 'jquery';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//document.addEventListener("scroll", function() {
  //var navbar = document.querySelector(".navbar");
  //navbar.classList.toggle("scrolled", window.scrollY > 0);
//});


function updateCategoryButtons() {
  // Set initial active button based on URL parameter
  var category = new URLSearchParams(window.location.search).get('category');
  if (category) {
    $('.category-buttons button[data-category="' + category + '"]').addClass('active');
  }

  // Handle button clicks
  $('.category-buttons button').on('click', function(event) {
    event.preventDefault();
    $('.category-buttons button').removeClass('active');
    $(this).addClass('active');
    var category = $(this).data('category');
    var url = new URL(window.location.href);
    url.searchParams.set('category', category);
    window.location.href = url.toString();
  });
}

$(document).ready(function() {
  updateCategoryButtons();
  $('.category-buttons button[data-category="all"]').on('click', function(event) {
    event.preventDefault();
    $('.category-buttons button').removeClass('active');
    $(this).addClass('active');
    var url = new URL(window.location.href);
    url.searchParams.delete('category');
    window.location.href = url.toString();
  });
});
